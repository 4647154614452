import UJS from '@rails/ujs'
import Turbolinks from 'turbolinks'
import mediumZoom from 'vendor/medium-zoom'
import { get } from 'axios'
import LocalTime from 'local-time'

Turbolinks.start()
LocalTime.start()
UJS.start()

let list
let pages
let page
let button
let loading

document.addEventListener('turbolinks:load', function () {
  mediumZoom('.document_content img', {
    background: 'rgba(255, 255, 255, .95)',
    scrollOffset: 0
  })

  list = document.querySelector('.site-collection')
  button = document.querySelector('.js-load')
  loading = document.querySelector('.js-loading')

  if (list && button) {
    button.addEventListener('click', loadPosts)
    pages = Number(list.dataset.pages)
    page = Number(list.dataset.page)
  }

  const navigationToggle = document.querySelector('.js-navigation-toggle')

  if (navigationToggle) {
    navigationToggle.addEventListener('click', () => {
      document.querySelector('.site-navigation.-collapsable').classList.toggle('-open')
      navigationToggle.classList.toggle('-active')

      document.querySelector('body').style.overflow = navigationToggle.classList.contains('-active') ? 'hidden' : null
    })
  }
})

const loadPosts = () => {
  if (page < pages) {
    page++
    list.setAttribute('data-page', page)
    button.classList.add('hidden')
    loading.classList.remove('hidden')

    get(`?page=${page}`).then(response => {
      const list = document.querySelector('.site-collection')
      list.innerHTML = list.innerHTML + response.data
      loading.classList.add('hidden')

      if (page !== pages) {
        button.classList.remove('hidden')
      }
    })
  }
}
